import React from "react"

import Layout from "../../../../components/layout-static"
import Seo from "../../../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Premises Liability Slip And Fall",
    description:
      "For fair compensation after being hurt on someone's property, contact Bartlett & Grippe. Specializing in Premises Liability, we believe property owners must keep safe conditions for guests.",
    heroH1: "Premises Liability Slip And Fall",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "hero-steps.jpg",
    heroImageAlt: "A sign outside which reads 'uneven steps'",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Injured on the Property of Another? Did you Slip and Fall?</h2>
            <h3>Get the Compensation You Deserve!</h3>
            <p className="mb-8">
              Staying safe from harm is a concern to everyone, especially
              property and business owners. For persons who are injured as a
              result of defective or dangerous conditions on someone else’s
              property, there is often legal recourse available to help you get
              fair compensation for the injuries you have sustained. Property
              owners owe a duty to maintain their properties in reasonably safe
              conditions for their customers and guests.
            </p>
            <p className="mb-8">
              Attorneys at Bartlett & Grippe, LLC have helped many people deal
              with injuries received while at a business or other property
              location. Premises Liability is an area of law that concerns all
              people. You should be able to visit a business or other property
              and be confident that you will not be injured while on those
              premises. If there is an injury case, it is essential to seek
              legal representation right away for a fair outcome.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <p className="mb-8">
              Clients seeking help from Premises Liability Attorneys call upon
              the law firm of Bartlett & Grippe, LLC, to recover compensation
              for injury-related expenses they received while on someone else’s
              property or business. Visitors should expect a property to be kept
              in good condition, properly cleaned and repaired so they would not
              become injured by accident. However, accidents do happen, and with
              an injury, there may be good cause to recover damages. Some
              examples of defective or dangerous conditions include:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>Snow and Ice</li>
              <li>Potholes</li>
              <li>Broken / Improperly Marked Curbing</li>
              <li>Missing Handrails</li>
              <li>Stairway Defects</li>
              <li>Spills</li>
              <li>Code Violations</li>
            </ul>
          </div>
          <h2>
            When is a Property Owner Potentially Liable for Injuries on their
            Property?
          </h2>
          <p className="mb-8">
            When a person is injured on property owned or controlled by another,
            they may be able to file a lawsuit. The plaintiff must prove the
            following elements to succeed in a premises liability lawsuit:
          </p>
          <ul className="list-disc list-outside ml-6 mb-8">
            <li>
              <strong>Duty of Care:</strong> Property owners have a duty to
              maintain their property in a reasonably safe condition for their
              customers and guests. Property owners further have a duty to
              inspect their properties for defective and dangerous conditions
              and to warn customers and guests of dangerous conditions they
              find, or should have found upon a reasonable inspection.
            </li>
            <li>
              <strong>Breach of Duty:</strong> In order for a property owner to
              have been negligent, they must have violated their duty of care.
              For the duty of care to have been breached, the property owner
              must have failed to maintain their property in a reasonably safe
              condition or failed to warn of a defective or dangerous condition.
            </li>
            <li>
              <strong>Notice:</strong> The plaintiff has the burden of proving
              that the dangerous or defective condition that caused their injury
              existed for a sufficient period of time that a property owner
              should have identified it. In cases involving snow and ice, the
              plaintiff must show that the accumulation of snow or ice existed
              for a sufficient period of time following the end of a snowstorm
              that a reasonable person should have been able to clear or treat
              the snow or ice. Some towns and cities have statutes and
              regulations setting forth what that reasonable time is.
            </li>
            <li>
              <strong>Causation:</strong> In addition to negligence, plaintiffs
              must also prove causation to have a valid claim. Injured persons
              must prove that the property owner’s breach of duty was the
              proximate cause of their injury, namely that they fell or were
              otherwise injured as a result of the defective or dangerous
              condition.
            </li>
            <li>
              <strong>Damages:</strong> The injury caused by the property
              owner’s negligence must have caused either economic or
              non-economic damages. This means that property owners cannot be
              held liable unless the injured person incurred medical bills, lost
              wages or damage to their future earning capacity, or pain and
              suffering.
            </li>
          </ul>
          <h2>What compensation are you entitled to?</h2>
          <p className="mb-8">
            In personal injury cases, compensatory damages are awarded to
            compensate the injured person for the harm caused by a property
            owner’s negligence. Compensatory damages attempt to make the victim
            “whole” again and provide financial compensation for economic and
            non-economic losses. Also known as actual damages, economic losses
            that may be covered include:
          </p>
          <ul className="list-disc list-outside ml-6 mb-8">
            <li>
              <strong>Medical expenses:</strong> These damages cover the cost of
              hospital stays, doctor visits, physical therapy, prescription
              drugs, assistive devices, and similar expenses. When an injury is
              severe or permanent, the personal injury victim may also be
              awarded compensation for future medical expenses.
            </li>
            <li>
              <strong>Lost wages:</strong> If you must take time away from work
              to recover from your injuries you may be able to collect
              compensation for lost wages.
            </li>
            <li>
              <strong>Loss of earning capacity:</strong> When a person is unable
              to earn the same amount of money as he or she did prior to their
              accident, they may be awarded compensation for a loss of earning
              capacity.
            </li>
            <li>
              <strong>Pain and suffering:</strong> Damages for pain and
              suffering typically compensate the victim for the physical pain
              caused by their injuries. These damages may also include
              compensation for emotional distress, which refers to anxiety,
              depression, fear, frustration, and other mental suffering that can
              develop as a result of an injury.
            </li>
            <li>
              <strong>Loss of Consortium:</strong> The spouse of the injured
              party may be able to recover compensation for the loss of marital
              benefits. Loss of companionship, sexual relations, affection, and
              comfort are among the compensable marital benefits. Loss of
              consortium damages is typically awarded in cases of life-changing
              or permanent injuries.
            </li>
            <li>
              <strong>Other Damages:</strong> There are other damages available
              in certain situations, including loss of life’s enjoyment,
              compensation for disability or disfigurement, loss of
              companionship, etc.
            </li>
          </ul>
          <p className="mb-8">
            Attorneys at the law firm of Bartlett & Grippe, LLC, are frequently
            called upon for help by residents of Litchfield, Harford, Middlesex,
            and New Haven Counties. Our expert attorneys are experienced with
            all types of personal injury cases, including premises liability
            injury cases. They can help you get past this distressing time and
            move forward with your life.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Page
